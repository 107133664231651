import Layout from '../components/Layout';
import PageHeader from '../components/PageHeader';
import Footer from '../components/Footer';
import React from 'react';
import { Box } from '@3pc/layout-components-react';
import { headerHeight } from '../components/PageHeader';
import { footerHeight } from '../components/Footer';
import styled from '@emotion/styled';
import { colors } from '../styles/colors';
import { devices } from '../styles/breakpoints';

const Heading = styled.h2`
  line-height: 1.3125em;
  margin-top: 30px;

  &:first-of-type {
    margin-top: 0;
  }

  &:nth-of-type(6) {
    margin-top: 5px;
  }
`;

const SubHeading = styled.h3`
  font-size: 0.875em;
  line-height: 1.3125em;
  margin-top: 30px;

  @media ${devices.mobileMin} {
    font-size: 1em;
  }
`;

const Paragraph = styled.p`
  margin-top: 10px;
  line-height: 1.3125em;

  address {
    display: inline;
  }
`;

const Address = styled.address`
  margin-top: 10px;
  line-height: 1.3125em;
  font-style: normal;
  font-size: 0.875em;

  @media ${devices.mobileMin} {
    font-size: 1em;
  }
`;

const ExLink = styled.a`
  border-bottom: 2px solid ${colors.blue};
  color: ${colors.black};
`;

const UList = styled.ul`
  line-height: 1.3125em;
  list-style: disc inside;
  font-size: 0.875em;

  @media ${devices.mobileMin} {
    font-size: 1em;
  }

  & li {
    list-style: disc inside;
    margin-top: 5px;
    text-indent: -1em;
    padding-left: 1em;
  }
`;

const PrivacyPolicyPage = () => {
  return (
    <>
      <PageHeader
        headerTitle="Datenschutzerklärung"
        pageType={'sublist-page'}
      />

      <Layout>
        <Box
          mt={headerHeight.headerMobile}
          mb={footerHeight.footerMobile}
          p="20px"
          pt={['22px', '32px']}
          mx={['auto', '18%']}
          maxWidth="553px"
        >
          <Heading>
            Hinweise zur Verarbeitung Ihrer Daten gemäß Art. 13 der Europäischen
            Datenschutz-Grundverordnung (DSGVO)
          </Heading>
          <Paragraph>
            Verweise auf gesetzliche Vorschriften beziehen sich auf die
            Europäische Datenschutz-Grundverordnung (DS-GVO) sowie das{' '}
            <ExLink href="https://www.rv.hessenrecht.hessen.de/bshe/document/jlr-DSIFGHEV1IVZ">
              Hessische Datenschutz- und Informationsfreiheitsgesetz (HDSIG)
            </ExLink>{' '}
            in der ab dem 25. Mai 2018 geltenden Fassung.
          </Paragraph>

          <Heading>Geltungsbereich</Heading>
          <Paragraph>
            Diese Datenschutzerklärung gilt für das Internet-Angebot der
            Hessischen Landeszentrale für politische Bildung (HLZ) unter{' '}
            <ExLink href="https://www.hessische-verfassung-app.de">
              www.hlz.hessen.de
            </ExLink>{' '}
            und für die über diese Internetseiten erhobenen Daten.
          </Paragraph>

          <Heading>Verantwortlichkeit</Heading>
          <Paragraph>
            Verantwortlich für die Verarbeitung personenbezogener Daten auf
            diesen Internetseiten ist:
          </Paragraph>
          <Address>
            die Hessische Landeszentrale für politische Bildung <br />
            Taunusstraße 4-6 <br />
            65183 Wiesbaden <br />
            Tel.: <ExLink href="tel:+4961132554000">0611 3255-4000</ExLink>{' '}
            <br />
            Fax: <ExLink href="fax:+4961132554077">0611 3255-4077</ExLink>{' '}
            <br />
            Email:{' '}
            <ExLink href="mailto:poststelle@hlz.hessen.de">
              poststelle@hlz.hessen.de
            </ExLink>
          </Address>

          <Heading>
            Behördlicher Datenschutzbeauftrager gem. Art 35ff DSGVO bzw. § 5
            HDSIG
          </Heading>
          <Address>
            Axel Knoblich <br />
            Taunusstraße 4-6 <br />
            65183 Wiesbaden <br />
            Tel.: <ExLink href="tel:+4961132554060">0611 3255-4060</ExLink>{' '}
            <br />
            Fax: <ExLink href="fax:+4961132554077">0611 3255-4077</ExLink>{' '}
            <br />
            Email:{' '}
            <ExLink href="mailto:axel.knoblich@hlz.hessen.de">
              axel.knoblich@hlz.hessen.de
            </ExLink>
          </Address>

          <Heading>Unser Umgang mit Ihren Daten:</Heading>
          <Heading>Personenbezogene Daten</Heading>
          <Paragraph>
            Personenbezogene Daten sind nach Art. 4 DSGVO alle Informationen,
            die sich auf eine identifizierte oder identifizierbare natürliche
            Person beziehen. Als identifizierbar wird eine natürliche Person
            angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung
            zu einer Kennung wie einem Namen, zu einer Kennnummer, zu
            Standortdaten, zu einer Online-Kennung oder zu einem oder mehreren
            persönlichen Merkmalen identifiziert werden kann, die Ausdruck der
            physischen, physiologischen, genetischen, psychischen,
            wirtschaftlichen, kulturellen oder sozialen Identität dieser
            natürlichen Person sind.
          </Paragraph>

          <Heading>Nutzungsdaten</Heading>
          <Paragraph>
            Bei jeder Anforderung einer Internetdatei werden folgende nicht
            personenbeziehbare Zugriffsdaten für statistische Auswertungen
            gespeichert:
          </Paragraph>
          <UList>
            <li>
              angeforderte Seite (ohne übergebene Parameter) bzw. Name der
              aufgerufenen Datei
            </li>
            <li>Anmeldename auf dem aufrufenden Rechner (falls übertragen)</li>
            <li>Benutzername (bei Seiten, die eine Anmeldung erfordern)</li>
            <li>Cookies</li>
            <li>Datum und Uhrzeit der Anforderung</li>
            <li>
              Identifikation des verwendeten Browsers und Betriebssystems (falls
              übertragen)
            </li>
            <li>IP-Adresse des aufrufenden Rechners</li>
            <li>Seite, von der aus der Aufruf erfolgt ist (Referrer)</li>
            <li>Session-ID</li>
            <li>Status der Anforderung (OK, Seite nicht gefunden, usw.)</li>
            <li>Übertragene Datenmenge in Bytes</li>
            <li>Zugriffsart (GET, POST)</li>
          </UList>
          <Paragraph>
            Diese Daten werden nicht an Dritte weitergegeben. Aus Gründen der
            Datensicherheit, also um unerlaubte Zugriffe aufklären zu können
            oder Missbrauch der Internetseite verhindern zu können, wird die
            vollständige IP-Adresse des anfordernden Rechners gespeichert und
            sieben Tage nach dem Ende des Zugriffs automatisch gelöscht.
          </Paragraph>

          <Heading>Ihre Rechte</Heading>
          <ol>
            <li>
              <SubHeading>1. Recht auf Auskunft</SubHeading>
              <Paragraph>
                Sie können nach Art. 15 DSGVO oder § 52 HDSIG Auskunft über Ihre
                von uns verarbeiteten personenbezogenen Daten verlangen. In
                Ihrem Auskunftsantrag sollten Sie Ihr Anliegen präzisieren, um
                uns das Zusammenstellen der erforderlichen Daten zu erleichtern.
                Bitte beachten Sie, dass Ihr Auskunftsrecht durch die
                Vorschriften der § 24 Abs. 2, § 26 Abs. 2 und § 33 HDSIG sowie §
                52 Abs. 2 bis 5 HDSIG eingeschränkt wird.
              </Paragraph>
            </li>
            <li>
              <SubHeading>2. Recht auf Berichtigung</SubHeading>
              <Paragraph>
                Sollten die Sie betreffenden Angaben nicht (mehr) zutreffend
                sein, können Sie nach Art. 16 DSGVO oder § 53 HDSIG eine
                Berichtigung verlangen. Sollten Ihre Daten unvollständig sein,
                können Sie eine Vervollständigung verlangen.
              </Paragraph>
            </li>
            <li>
              <SubHeading>3. Recht auf Löschung</SubHeading>
              <Paragraph>
                Unter den Bedingungen des Art. 17 DSGVO und der §§ 34 und 53
                HDSIG können Sie die Löschung Ihrer personenbezogenen Daten
                verlangen. Ihr Anspruch auf Löschung hängt u. a. davon ab, ob
                die Sie betreffenden Daten von uns zur Erfüllung unserer
                gesetzlichen Aufgaben noch benötigt werden.
              </Paragraph>
            </li>
            <li>
              <SubHeading>
                4. Recht auf Einschränkung der Verarbeitung
              </SubHeading>
              <Paragraph>
                Im Rahmen der Vorgaben des Art. 18 DSGVO und § 53 HDSIG haben
                Sie das Recht, eine Einschränkung der Verarbeitung der Sie
                betreffenden Daten zu verlangen.
              </Paragraph>
            </li>
            <li>
              <SubHeading>5. Recht auf Widerspruch</SubHeading>
              <Paragraph>
                Nach Art. 21 DS-GVO haben Sie das Recht aus Gründen, die sich
                aus Ihrer besonderen Situation ergeben, jederzeit der
                Verarbeitung der Sie betreffenden Daten zu widersprechen.
                Allerdings können wir dem nicht immer nachkommen, z. B. wenn uns
                im Sinne von § 35 HDSIG im Rahmen unserer amtlichen
                Aufgabenerfüllung eine Rechtsvorschrift zur Verarbeitung
                verpflichtet.
              </Paragraph>
            </li>
            <li>
              <SubHeading>6. Recht auf Beschwerde</SubHeading>
              <Paragraph>
                Wenn Sie der Auffassung sind, dass wir bei der Verarbeitung
                Ihrer Daten datenschutzrechtliche Vorschriften nicht beachtet
                haben, können Sie sich mit einer Beschwerde an die zuständige
                Aufsichtsbehörde wenden.
              </Paragraph>
              <Paragraph>
                Das ist der Hessische Beauftragte für Datenschutz und
                Informationsfreiheit,{' '}
                <Address>
                  Gustav-Stresemann-Ring 1, 65189 Wiesbaden, Tel. 0611 / 1408-0.
                </Address>
              </Paragraph>

              <Paragraph>
                Allgemeines zum Thema Datenschutz ist auf den Internetseiten des
                Hessischen Datenschutzbeauftragten zu finden:{' '}
                <ExLink href="https://datenschutz.hessen.de/">
                  www.datenschutz.hessen.de.
                </ExLink>
              </Paragraph>
              <Paragraph>
                Bei Fragen zum Datenschutz bei der Hessischen Landeszentrale für
                politische Bildung können Sie sich an den behördlichen
                Datenschutzbeauftragten wenden.
              </Paragraph>
            </li>
          </ol>
        </Box>
      </Layout>

      <Footer />
    </>
  );
};

export default PrivacyPolicyPage;
